import React from 'react';
// import logo from './logo.svg';
import RSAppBar from './structureComponents/AppBar';
import RSTheme from './utilities/RSTheme';
import {ThemeProvider} from '@mui/material/styles';
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';
//import LandingPage from './pages/LandingPage';
import ChefsPage from './pages/ChefsPage';
// import RecipePage from './pages/RecipePage';
import RecipesPage from './pages/RecipesPage';
import LoginPage from './pages/LoginPage';
import './App.css';
import { Amplify } from 'aws-amplify';

Amplify.configure({
  aws_cognito_region: window.config.awsRegion,
  aws_user_pools_id: window.config.userPoolId,
  aws_user_pools_web_client_id: window.config.clientId,
  aws_mandatory_sign_in: 'enable'
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={RSTheme}>
        <Router>
          <RSAppBar/>
          <Routes>
            <Route path="/" element={<LoginPage />}/>
            <Route path="/Recipes" element={<RecipesPage />} />
            <Route path="/Chefs" element={<ChefsPage />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
