import React from 'react';
import { withStyles } from '@mui/styles';
import { TextField } from '@mui/material';

const styles = (theme) => ({
    '@global': {
        '.MuiInputBase-root': {
            height: '35px',
        }
    },
    textStyles: {
        margin: '10px !important'
    }
});



function FormattedTextField(props) {
  //const [password, setPassword] = useState("");
  var {classes, key, placeholder, value, onChange } = props;

  return (
    <TextField 
        key={key}
        placeholder={placeholder || "Enter Value..."}
        className={classes.textStyles} 
        value={value} 
        onChange={onChange} 
    />
  );
}

export default withStyles(styles)(FormattedTextField);