import React from 'react';
import { withStyles } from '@mui/styles';
import { Button, Modal } from '@mui/material';

const styles = (theme) => ({
    modalWrapper: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#ffffff !important',
        borderRadius: '2px',
        top: '50%',
        left: '50%',
        width: '50vw',
        transform: 'translate(-50%, -50%)',
        position: 'absolute'
    },
    modalBody: {
        display: 'flex',
        flex: 1
    },
    actionRow: {
        width: '100%',
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end'
    },
    buttons: {
        margin: '10px !important'
    }
});



function FormattedModal(props) {
  //const [password, setPassword] = useState("");
  var {classes, open, onClose, onSubmit, title, children, buttonName, submitDisabled} = props;

  return (
    <Modal
        open={open}
        onClose={onClose}
    >
        <div className={`col-xs-6 ${classes.modalWrapper}`}>
            <h2>
                {
                    title
                }
            </h2>
            <div className={classes.modalBody}>
                {
                    children
                }
            </div>
            <div className={classes.actionRow}>
                <Button 
                    className={classes.buttons}
                    variant="contained"
                    onClick={onSubmit}
                    disabled={submitDisabled}
                >
                    { buttonName || "Submit" }
                </Button>
                <Button 
                    className={classes.buttons}
                    variant="contained"
                    onClick={onClose}
                >
                    Close
                </Button>
            </div>
        </div>
    </Modal>
  );
}

export default withStyles(styles)(FormattedModal);