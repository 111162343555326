import React from 'react';
import { withStyles } from '@mui/styles';
// import { Button, TextField } from '@mui/material';
// import { Auth } from 'aws-amplify';
// import { useNavigate } from "react-router-dom";

const styles = (theme) => ({
    wrapper: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center'
    }
});



function RecipePreview(props) {
  //const [password, setPassword] = useState("");

  return (
    <>
      This is a Recipe
    </>
  );
}

export default withStyles(styles)(RecipePreview);