import React, {Component} from 'react';
import {IconButton, Popover} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { withStyles } from '@mui/styles';
import { Link } from "react-router-dom";

const styles = (theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  listMenu: {
    padding: 10
  },
  removeUnderline: {
    textDecoration: "none"
  },
  listItems: {
    paddingVertical: "15 !important",
    paddingHorizontal: 10
  }
})

class HamburgerButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null
    }
  }

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      open: true
    })
  };

  handleClose = (event) => {
    this.setState({
      anchorEl: null,
      open: false
    })
  };


  render() {
    var { classes } = this.props;
    var { open, anchorEl } = this.state;
    return (
      <React.Fragment>
        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={this.handleClick}>
          <MenuIcon />
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className={classes.listMenu}>
              <div className={ classes.listItems}>
                <Link className={ classes.removeUnderline} to="/">Landing Page</Link>
              </div>
              <div className={ classes.listItems}>
                <Link className={ classes.removeUnderline} to="/Chefs">Chefs</Link>
              </div>
              <div className={ classes.listItems}>
                <Link className={ classes.removeUnderline} to="/Recipes">Recipes</Link>
              </div>
          </div>
        </Popover>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(HamburgerButton);
