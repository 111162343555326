import React, { useRef } from 'react';
import { withStyles } from '@mui/styles';
import { FormControl, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';

const styles = (theme) => ({
    formWrapper: {
      margin: '10px !important'
    },
    labelWrapper: {
      margin: '-10px !important'
    }
});



function FileUploaderSimple(props) {
  const inputRef = useRef(null);
  var {classes, label, fileName, setFileName, setFileObj, acceptedFiles} = props;

  const handleClickUpload = () => {
    inputRef.current.click();
  };

  const fileSelected = event => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      event.target.value = null;
      return;
    }
    setFileObj(fileObj);

    // 👇️ reset file input

    // 👇️ can still access file object here
    console.log(event);
    console.log(fileObj);
    console.log(fileObj.data);
    console.log(inputRef);
    setFileName(fileObj.name);
  };
  console.log('1', inputRef.current?.files);

  return (
    <FormControl
      className={classes.formWrapper}
      color='secondary'
    >
      <OutlinedInput
        ref={inputRef}
        value={fileName}
        disabled={true}
        placeholder={label || "Upload File"}
        onClick={handleClickUpload}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              edge="end"
            >
              <FileUploadIcon />
            </IconButton>
          </InputAdornment>
        }
      />
      <input
        style={{display: 'none'}}
        ref={inputRef}
        type="file"
        accept={acceptedFiles}
        onChange={fileSelected}
      />
    </FormControl>
  );
}

export default withStyles(styles)(FileUploaderSimple);