import React, {Component} from 'react';
import { withStyles } from '@mui/styles';

const styles = (theme) => ({
})

class ChefsPage extends Component {
  // constructor(props) {
  //   super(props);
  // }
  render() {
    // var { classes } = this.props;
    return (
      <div>
        Chefs Page
      </div>
    )
  }
}

export default withStyles(styles)(ChefsPage);
