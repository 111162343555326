import React, {Component} from 'react';
import { withStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import Button from '@material-ui/core/Button';
// import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';
import HamburgerButton from './HamburgerButton';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  flexWrapper: {
    display: "flex"
  }
})

class RSAppBar extends Component {
  // constructor(props) {
  //   super(props);

  // }
  render() {
    var {classes} = this.props
    return (

      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Recipe Share
          </Typography>
          <HamburgerButton/>
        </Toolbar>
      </AppBar>
      // <AppBar position="static">
      //   <Toolbar>
      //     <div className={classes.flexWrapper}>
      //         <Typography className='col-11' variant="h6">
      //           Recipe Share
      //         </Typography>
      //         <HamburgerButton className='col-1'/>
      //       </div>
      //   </Toolbar>
      // </AppBar>
    )
  }
}
export default withStyles(styles)(RSAppBar);
