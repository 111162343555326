import React, {useState} from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { setRecipesData } from '../actions/RecipeActions';
import { Button } from '@mui/material';
import FormattedModal from '../structureComponents/FormattedModal';
import RecipePreview from '../structureComponents/RecipePreview';
import FormattedTextField from '../structureComponents/FormattedTextField';
import FileUploaderSimple from '../structureComponents/FileUploaderSimple';
import APIEndPoints from '../services/apiEndpoints';


const axios = require('axios')

const styles = (theme) => ({
  buttonWrapper: {
    margin: '10px !important'
  }
})


function RecipesPage(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [recipeName, setRecipeName] = useState("");
  const [previewImageName, setPreviewImageName] = useState("");
  const [previewImageObj, setPreviewImageObj] = useState(null);
  const [recipeFileName, setRecipeFileName] = useState("");
  const [recipeFileObj, setRecipeFileObj] = useState(null);
  var { classes } = props;

  const submitRecipe = async () => {
    var previewFileKey = await uploadPreviewImage();
    var recipeFileKey = await uploadRecipeFile();
    await submitRecipeData(previewFileKey, recipeFileKey);
  };
  
  const uploadPreviewImage = async () => {
    var token = localStorage.getItem("token");
    var responseObj;

    await axios({
      method: 'post',
      url: APIEndPoints.GET_SIGNED_URL,
      data: { 
        folder:"PreviewImage", 
        fileType: previewImageObj.type, 
        fileName: previewImageName
      },
      headers: {'Authorization': 'Bearer ' + token},
    })
      .then((response) => {
        console.log(response)
        responseObj = response.data;
      });
      
    await uploadFile(responseObj.url, previewImageObj);
    return responseObj.fileKey;
  };

  const uploadRecipeFile = async () => {
    var token = localStorage.getItem("token");
    var responseObj;
    
    await axios({
      method: 'post',
      url: APIEndPoints.GET_SIGNED_URL,
      data: { 
        folder:"Recipe", 
        fileType: recipeFileObj.type, 
        fileName: recipeFileName
      },
      headers: {'Authorization': 'Bearer ' + token},
    })
      .then((response) => {
        console.log(response)
        responseObj = response.data;
      });
    
    await uploadFile(responseObj.url, recipeFileObj);
    return responseObj.fileKey;
  };

  const uploadFile = async (signedUrl, fileObj) => {
    console.log(fileObj)
    console.log(fileObj.type)
    await axios({
      method: 'put',
      url: signedUrl,
      data: fileObj,
      headers: {
        'Content-Type': fileObj.type,
        'Content-Length': fileObj.size
      }
    })
      .then((response) => {
        console.log(response)
      });
  };

  const submitRecipeData = async (previewImageKey, recipeFileKey) => {
    var token = localStorage.getItem("token");

    await axios({
      method: 'post',
      url: APIEndPoints.POST_RECIPE,
      data: { 
        previewImageKey,
        recipeFileKey
      },
      headers: {'Authorization': 'Bearer ' + token},
    })
      .then((response) => {
        console.log(response);
      });
  };

  return (
    <React.Fragment styles={{flex:1}}>
      <div>
        Recipes Page
      </div>
      <div className='row'>
        <div className="col-xs-9">Col 1</div>
        <div className='col-xs-3'>
          <Button
            className={`${classes.buttonWrapper}`}
            variant="contained"
            onClick={() => setModalOpen(true)}
          >
            Post Recipe
          </Button>
        </div>
      </div>
      {
        props.recipeList.map((recipe) => {
          return <RecipePreview />;
        })
      }
      <FormattedModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSubmit={submitRecipe}
        title='Post Recipe'
        submitDisabled={!recipeName}
      >
        <div>
          <FileUploaderSimple 
            label='Upload Preview Image'
            fileName={previewImageName}
            setFileName={setPreviewImageName}
            fileObj={previewImageObj}
            setFileObj={setPreviewImageObj}
            acceptedFiles='image/*'
          />
          <FormattedTextField 
            key='recipeName'
            placeholder='Recipe Name'
            value={recipeName} 
            onChange={(e) => setRecipeName(e.target.value)}
          />
          <FileUploaderSimple 
            label='Upload Recipe File'
            fileName={recipeFileName}
            setFileName={setRecipeFileName}
            fileObj={recipeFileObj}
            setFileObj={setRecipeFileObj}
          />
        </div>
      </FormattedModal>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    recipeList: state.RecipeDataReducer.recipeList
  };
}

const mapDispatchToProps = (dispatch) => ({
  setRecipesData: (recipeArray) => dispatch(setRecipesData(recipeArray))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RecipesPage));
