import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { Button, TextField } from '@mui/material';
import { Auth } from 'aws-amplify';
import { useNavigate } from "react-router-dom";

const styles = (theme) => ({
    wrapper: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center'
    },
    textFields: {
      width: '30vw',
      margin: '15px !important'
    },
    buttons: {
      height: 56,
      width: '30vw',
      margin: '15px !important'
    },
    link: {
      color: "-webkit-link",
      cursor: "pointer",
      textDecoration: "underline",
    }
});



function LoginPage(props) {
  const [email, setEmail] = useState("");
  const [currentComponent, setCurrentComponent ] = useState("SignIn");
  

  return (
    <>
      {
        currentComponent === "SignIn" &&
        <SignIn
          { ...props }
          email={email}
          setEmail={setEmail}
          setCurrentComponent={setCurrentComponent}
        />
      }
      {
        currentComponent === "RequestReset" &&
        <RequestReset
          { ...props }
          email={email}
          setEmail={setEmail}
          setCurrentComponent={setCurrentComponent}
        />
      }
      {
        currentComponent === "ResetPassword" &&
        <ResetPassword
          { ...props }
          email={email}
          setCurrentComponent={setCurrentComponent}
        />
      }
      {
        currentComponent === "SignUp" &&
        <SignUp
          { ...props }
          email={email}
          setEmail={setEmail}
          setCurrentComponent={setCurrentComponent}
        />
      }
    </>
  );
}

function SignIn(props) {
  const { classes, email, setEmail, setCurrentComponent } = props;
  const [password, setPassword] = useState("");
  let navigate = useNavigate();

  async function signIn() {
      try {
          const user = await Auth.signIn(email, password);
          console.log('user', user);
          localStorage.setItem("user", user.attributes);
          localStorage.setItem("userSession", user.signInUserSession);
          localStorage.setItem("token", user.signInUserSession.idToken.jwtToken);
          navigate("/Recipes", { replace: true });
      } catch (error) {
          console.log('error signing in', error);
      }
  }

  async function signUp() {
    setEmail("");
    setCurrentComponent("SignUp");
  }

  async function resetPassword() {
    setCurrentComponent("RequestReset");
  }

  return (
    <div className={`${classes.wrapper}`}>
      <TextField 
        className={`${classes.textFields}`}
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField 
        className={`${classes.textFields}`}
        label="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        type="password"
      />
      <div
        className={`${classes.textFields} ${classes.link}`}
        onClick={ () => resetPassword()}
      >
        Forgot Password?
      </div>
      <Button 
        variant="contained"
        className={`${classes.buttons}`}
        onClick={() => signIn()}
      >
        Sign In
      </Button>
      <Button 
        className={`${classes.buttons}`}
        onClick={() => signUp()}
      >
        Sign Up
      </Button>
    </div>
  );
}


function SignUp(props) {
  const { classes, email, setEmail, setCurrentComponent } = props;
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nickName, setNickName] = useState("");

  function submitSignUp() {
    Auth.signUp({
      username: email,
      password: password,
      attributes: {
          email: email,
          phone_number: phoneNumber, // E.164 number convention
          given_name: firstName,
          family_name: lastName,
          nickname: nickName
      }
    })
      .then((res) => {
        console.log("Created User:", res);
        setCurrentComponent("SignIn");
      })
      .catch((e) => {
        console.log("Failed Creating User:", e);
      })
  }

  return (
    <div className={`${classes.wrapper}`}>
      <TextField 
        className={`${classes.textFields}`}
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField 
        className={`${classes.textFields}`}
        label="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        type="password"
      />
      <TextField 
        className={`${classes.textFields}`}
        label="Phone Number"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
      />
      <TextField 
        className={`${classes.textFields}`}
        label="First Name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
      />
      <TextField 
        className={`${classes.textFields}`}
        label="Last Name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
      />
      <TextField 
        className={`${classes.textFields}`}
        label="Nick Name"
        value={nickName}
        onChange={(e) => setNickName(e.target.value)}
      />
      <Button 
        variant="contained"
        className={`${classes.buttons}`}
        onClick={() => submitSignUp()}
      >
        Sign Up
      </Button>
    </div>
  );
}


function RequestReset(props) {
  const { classes, email, setEmail, setCurrentComponent } = props;

  async function submitResetRequest() {
    await Auth.forgotPassword(email).then(() => {
        console.log('a verification code is sent');
        setCurrentComponent("ResetPassword");
      }).catch((e) => {
        console.log('failed with error', e);
      });
  }

  return (
    <div className={`${classes.wrapper}`}>
      <TextField 
        className={`${classes.textFields}`}
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button 
        variant="contained"
        className={`${classes.buttons}`}
        onClick={() => submitResetRequest()}
      >
        Reset Password
      </Button>
    </div>
  );
}


function ResetPassword(props) {
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [verificationCode, setVerificationCode ] = useState("");
  // const [isError, setIsError ] = useState(false);
  const { classes, email, setCurrentComponent } = props;

  async function submitPassword() {
    try {
        await Auth.forgotPasswordSubmit(email, verificationCode, password1);
        setCurrentComponent("SignIn");
    } catch (error) {
        // setIsError(true)
        console.log('error signing in', error);
    }
  }

  return (
    <div className={`${classes.wrapper}`}>
      <TextField 
        className={`${classes.textFields}`}
        label="New Password"
        value={password1}
        type="password"
        onChange={(e) => setPassword1(e.target.value)}
      />
      <TextField 
        className={`${classes.textFields}`}
        label="Confirm Password"
        value={password2}
        type="password"
        onChange={(e) => setPassword2(e.target.value)}
      />
      <TextField 
        className={`${classes.textFields}`}
        label="Verification Code"
        value={verificationCode}
        onChange={(e) => setVerificationCode(e.target.value)}
      />
      <Button 
        variant="contained"
        className={`${classes.buttons}`}
        onClick={() => submitPassword()}
        disabled={
          password1 !== password2 ||
          password1.length < 8 ||
          verificationCode.length !== 6
        }
      >
        Reset Password
      </Button>
    </div>
  );
}

export default withStyles(styles)(LoginPage);