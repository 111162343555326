import { SET_RECIPES_DATA } from '../actions/RecipeActions';

const initialState = {
    recipeList: [{recipeId: "1"}]
};

const recipeDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_RECIPES_DATA:
            return Object.assign({}, state, {
                recipeList: action.payload
            });
        default:
            return state;
    }
};

export default recipeDataReducer;